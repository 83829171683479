
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";

export default defineComponent({
  name: "view-users-modal",
  components: {},
  data() {
    return {
      path: "",
      book_id: "",
      list: []
    };
  },
  methods: {
    initData(path, book_id) {
      this.path = path;
      this.book_id = book_id;
      const data = {
        book_id: book_id
      };
      ApiService.post("Exercise/Chapter", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.list = data.data;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    handleExercise(e) {
      const data = {
        book_id: this.book_id,
        exercise_type: "03",
        group_id: e.currentTarget.dataset.group_id,
        path: this.path
      };

      this.$router.push({
        path: "/exercise/start",
        query: data
      });
    }
  }
});
