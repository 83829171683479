import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-3 mb-3" }
const _hoisted_3 = { class: "menu-item px-3 mb-3" }
const _hoisted_4 = { class: "menu-item px-3 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "menu-link px-3",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClear('00')))
      }, " 清空全部数据 ")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "menu-link px-3",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClear('01')))
      }, " 清空练习数据 ")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "menu-link px-3",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClear('02')))
      }, " 清空模拟考试数据 ")
    ])
  ]))
}