
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import { setCurrentPageButton } from "@/core/helpers/toolbar";
import Bus from "@/common/ts/Bus";
import ExerciseQuestionType from "@/views/exercise/ExerciseQuestionType.vue";
import ExerciseChapter from "@/views/exercise/ExerciseChapter.vue";
import ClearManager from "@/views/exercise/clear/ClearManager.vue";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    ExerciseQuestionType,
    ExerciseChapter,
    ClearManager
  },
  data() {
    return {
      path: "",
      book_id: "",
      row: {},
      //分页
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: 0
      },
      //搜索
      searchKey: "",
      //排序
      sortField: "create_time",
      sortOrder: "descending",
      //数据
      tableData: []
    };
  },
  methods: {
    /**
     * 查找试题
     */
    handleSearch() {
      this.$router.push({
        path: "/exercise/search",
        query: { book_id: this.book_id, path: this.path }
      });
    },

    /**
     * 模拟考试
     */
    handleMock() {
      this.$router.push({
        path: "/mock/ready",
        query: { book_id: this.book_id, path: this.path }
      });
    },

    /**
     * 题型练习
     */
    handleQuestionType() {
      const path = this.path;
      const book_id = this.book_id;
      (this.$refs.ExerciseQuestionType as any).initData(path, book_id);
    },

    /**
     * 章节练习
     */
    handleChapter() {
      const path = this.path;
      const book_id = this.book_id;
      (this.$refs.ExerciseChapter as any).initData(path, book_id);
    },

    handleExercise(e) {
      const exercise_type = e.currentTarget.dataset.exercise_type;
      if (exercise_type == "00" || exercise_type == "01") {
        this.$router.push({
          path: "/exercise/start",
          query: {
            book_id: this.book_id,
            exercise_type: exercise_type,
            path: this.path
          }
        });
      }
    },

    getDetail() {
      const data = {
        book_id: this.book_id
      };
      ApiService.post(
        "ExerciseBook/ExerciseBookDetail",
        data as AxiosRequestConfig
      )
        .then(({ data }) => {
          if (data.success == true) {
            this.row = data.data;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    loadData() {
      const data = {
        currentPage: this.pagination.currentPage,
        perPage: this.pagination.perPage,
        searchKey: this.searchKey,
        sortField: this.sortField,
        sortOrder: this.sortOrder
      };
      ApiService.post(
        "ExerciseBook/ExerciseBookList",
        data as AxiosRequestConfig
      )
        .then(({ data }) => {
          if (data.success == true) {
            this.tableData = data.data.items;
            this.pagination.totalRows = data.data.meta.totalRows;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    handleSizeChange(event) {
      this.pagination.perPage = parseInt(event.target.value);
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.loadData();
    },
    initBus() {
      Bus.$on("handleBack", () => {
        this.$router.push({ path: this.path });
      });
    }
  },
  mounted() {
    setCurrentPageBreadcrumbs("选择模式", ["练习"]);
    setCurrentPageButton("back", true);
    this.initBus();
    //this.row = JSON.parse(this.$route.params.row as string);
    this.path = this.$route.query.path as string;
    this.book_id = this.$route.query.book_id as string;
    this.getDetail();
  }
});
