
import { defineComponent } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";

export default defineComponent({
  name: "dropdown-3",
  props: {
    book_id: String
  },
  methods: {
    submitClear(type) {
      const data = {
        book_id: this.book_id,
        type: type
      };
      ApiService.post("Exercise/ExerciseClear", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            Toast.warning(data.message);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    handleClear(type) {
      let message = "";
      if (type == "00") {
        message = "练习和模拟考试";
      } else if (type == "01") {
        message = "练习";
      } else if (type == "02") {
        message = "模拟考试";
      }
      Toast.confirm("请确定是否清空" + message + "数据？", () => {
        this.submitClear(type);
      });
    }
  },
  mounted() {
    MenuComponent.reinitialization();
  }
});
